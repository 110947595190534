// after load
$(window).load(function() {
    if (widthviewport > 800){
        // class animate
        // $('.svg--logo-qdh').addClass('animate');
        setTimeout(function() {                
            $('.slogan__wrapper').addClass('animate');
        }, 1000);
    }
    else{
        // class animate
        // $('.svg--logo-qdh').addClass('animate');
        $('.slogan__wrapper').addClass('animate');
    }
});

$(document).ready(function(){
    
    if (widthviewport > 800){
        /*** Scroll Magic ***/
        // init controller
        var controller = new ScrollMagic.Controller();

        // SERVICES
        new ScrollMagic.Scene({triggerElement: "#about", triggerHook: "onEnter", offset: 300, reverse: false})
            .setClassToggle(".ico--lamp, .ico--phone, .ico--plus, .ico--plus--hor, .ico--plus--ver, .service__desc", "animate")
            .addTo(controller);
    }
    else{
        $(document).ready(function() {
            $('.service__desc, .ico--lamp, .ico--phone, .ico--plus').addClass('normal');
        });
    }

    // filter projects 
    var menu = '[data-toggle="menu-tab"]'

    $(menu, '.menu-work').on('click', filter)

    function filter(e) {
        e.preventDefault();

        var $this   = $(this)
        var category = getCategory()
        var target = '.thumbnail-work-group'
        
        setMenuActive()
        categoryExists()

        function getCategory () {
            return $this.attr('data-category')
        }

        function categoryExists() {
            $(target).each(function(i, el){
                if($(this).attr('data-tab') === category) {
                    $(this).fadeIn('400')
                } else {
                    $(this).fadeOut('200')
                }


            })
        }

        function setMenuActive() {
            $(menu).parent().removeClass('active')
            $this.parent().addClass('active')
        }
    }
    //end filter project 
    
    // hover client logo script 
    /*(function(window){
        var $ = window.jQuery;
        var $target = $('.client-logo');

        $target.on('mouseover', toggleSrc)
        $target.on('mouseleave', toggleSrc)
        
        function toggleSrc () {
            var $this = $(this);
            var srcOrigin = getSrcOrigin();
            var dataSrc = getDataSrc();

            setDataSrc(srcOrigin);
            setSrcOrigin(dataSrc);
            
            function getDataSrc () {
                return $this.attr('data-src-toggle'); 
            }

            function getSrcOrigin () {
                return $this.attr('src');
            }

            function setDataSrc (dataSrc) {
                return $this.attr('data-src-toggle', dataSrc);
            }

            function setSrcOrigin (srcOrigin) {
                return $this.attr('src', srcOrigin);
            }
        }

    })(window); */

    
    // anchor work
    $('.slow-scroll').on('click', function(e){
        e.preventDefault();
         $('html, body').animate({
            scrollTop: $("#ourWork").offset().top
        }, 1000);
    });

});


/*if (widthviewport > 768){

    $(document).scroll(function() {    

        var scrollPos = ($(document).scrollTop() + 62);
        var sections = $('.section__navmenu');    
        $('.navmenu a.navmenu__anchorscroll').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href").match(/#(.*$)/)[0]);
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('.navmenu ul li').removeClass("active");
                currLink.parent().addClass("active");
            }
            else{
                currLink.parent().removeClass("active");
            }
        });

    });
}
*/



